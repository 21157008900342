import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MgButtonComponent } from '@marketguru/core';
import { IntensiveTimerService } from '../intensive-timer/intensive-timer.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MgButtonComponent]
})
export class CookieBannerComponent implements OnInit {
  isShowBanner = false;
  bottomHeight = 0;
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private timerService: IntensiveTimerService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isShowBanner = !localStorage?.getItem('showed-cookie-banner');

      this.timerService.timerHeight$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        this.bottomHeight = value || 0;
        this.cdr.detectChanges();
      });
    }
  }

  onClose(): void {
    this.isShowBanner = false;
    localStorage?.setItem('showed-cookie-banner', 'true');
  }
}
