import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling
} from '@angular/router';
import { MgThemeService } from '@marketguru/core';
import { MG_PRODUCTION, MG_PUBLIC_PAGE, MG_SALE_CONFIG } from '@marketguru/shared';
import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { SALE_CONFIG } from './shared/constants/sale-config';
import { apiPrefixInterceptor } from './shared/interceptors/api-prefix.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' })
    ),
    provideClientHydration(withNoHttpTransferCache()),
    provideHttpClient(withFetch(), withInterceptors([apiPrefixInterceptor])),
    provideAnimations(),
    { provide: MG_SALE_CONFIG, useValue: SALE_CONFIG },
    { provide: MG_PUBLIC_PAGE, useValue: true },
    { provide: MG_PRODUCTION, useValue: environment.production },
    NG_EVENT_PLUGINS,
    MgThemeService
  ]
};
