<div class="nav-header">
  <mg-logo height="4.2rem" />

  <button
    (click)="closeMenu.emit()"
    appearance="secondary"
    class="mobile ml-16"
    mg-button-icon
    iconLeft="mg-icon-close"
  ></button>
</div>

<a
  [href]="mainHost + '/auth/signup' + utmService.utmQuery() + '&utm_button_target=signup-button-header'"
  accent
  class="nav-signup"
  data-testid="signup-button-header"
  mg-button
>
  Попробовать бесплатно
</a>

<div class="nav-list">
  @for (item of menuItems; track item.name) {
    <div class="menu-item">
      @if (item.children) {
        <span class="menu-item__link">
          {{ item.name }}
          <i class="mg-icon-chevron-down"></i>
        </span>

        <div class="menu-item-children">
          @for (childItem of item.children; track childItem.name) {
            @if (childItem.href) {
              <a class="menu-item-children__link" [href]="childItem.href" target="_blank">{{ childItem.name }}</a>
            } @else {
              <a (click)="close()" class="menu-item-children__link" [routerLink]="childItem.routerLink">
                {{ childItem.name }}
              </a>
            }
          }
        </div>
      } @else if (item.href) {
        <a class="menu-item__link" [href]="item.href" target="_blank">{{ item.name }}</a>
      } @else {
        <a (click)="close()" class="menu-item__link" [routerLink]="item.routerLink">{{ item.name }}</a>
      }
    </div>
  }
</div>
