import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { MgButtonComponent } from '@marketguru/core';
import { MgLogoComponent } from '@marketguru/shared';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { utmList } from '../../constants/utm-list';
import { UtmService } from '../../services/utm.service';
import { wizardUrl } from '../../utils/wizard-url';
import { HeaderNavComponent } from './header-nav/header-nav.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MgLogoComponent,
    MgButtonComponent,
    HeaderNavComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  isDocsPage = input(false);

  protected readonly utmService = inject(UtmService);
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #serializer = inject(UrlSerializer);
  readonly #destroyRef = inject(DestroyRef);

  readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  readonly mainHost = wizardUrl();

  isNavOpened = signal(false);
  isShowRegButton = signal(false);

  ngOnInit(): void {
    this.#route.queryParams
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe((queryParams) => {
        const utmParams = this.#serializer.serialize(
          this.#router.createUrlTree(['/'], { queryParams })
        );

        if (this.isBrowser) {
          utmList.forEach((utm) => {
            if (queryParams[utm]) {
              localStorage.setItem(utm, queryParams[utm]);
            }
          });
        }

        if (queryParams['modal'] === 'login') {
          window.location.replace(this.mainHost + '/auth/signin');
        }
        if (queryParams['r']) {
          location.href = this.mainHost + utmParams;
        }
      });

    if (this.isBrowser) {
      fromEvent(window, 'scroll')
        .pipe(
          debounceTime(10),
          map(() => window.scrollY > 500),
          distinctUntilChanged(),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe((isShowRegButton) => {
          this.isShowRegButton.set(isShowRegButton);
        });
    }
  }

  navToggle() {
    this.isNavOpened.update((isNavOpened) => !isNavOpened);
  }
}
