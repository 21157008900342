import { Routes } from '@angular/router';
import { EducationModule } from '@marketguru/shared';
import { AppLayoutComponent } from './shared/components/app-layout/app-layout.component';
import { UtmService } from './shared/services/utm.service';

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    providers: [UtmService],
    children: [
      {
        path: '',
        data: {
          title: 'MarketGuru - сервис аналитики и увеличения продаж на Wildberries',
          description:
            'Сервис аналитики и увеличения продаж на Wildberries - MarketGuru. Найди прибыльные ниши, тренды, планируй поставки, выводите товары в ТОП на Вайлдберриз.'
        },
        loadComponent: () => import('./public/home/home.component').then((c) => c.HomeComponent)
      },
      {
        path: 'prices',
        data: {
          title:
            'Тарифы успешного бизнеса с МаркетГуру - Сервис аналитики и увеличения продаж на Wildberries',
          description:
            'Повысьте свои продажи, попадите в топ 10 по запросам, настройте процессы поставок и получите инструменты подробной аналитики для принятия эффективных решений. Управляйте тысячами товаров за счет автоматизации. Получайте рекомендации на какой товар дать акцию, от какого избавиться, какой закупить.'
        },
        loadComponent: () =>
          import('./public/tariffs/tariffs.component').then((c) => c.TariffsComponent)
      },
      {
        path: 'privacy',
        data: {
          title: 'MarketGuru — Лицензионный договор — Оферта',
          description:
            'Лицензионный договор — Оферта. Настоящая публичная Оферта (далее по тексту — Договор) является официальным предложением Общества с ограниченной ответственностью “МаркетГуру” (ОГРН 1216100023897, ИНН 6154160950), действующего без образования юридического лица (далее по тексту – Лицензиар) в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса РФ'
        },
        loadComponent: () => import('./public/privacy/privacy.component').then((c) => c.PrivacyComponent)
      },
      {
        path: 'privacy-promotion',
        data: {
          title: 'MarketGuru — Оферта для услуг продвижения',
          description:
            'Оферта для услуг продвижения. Настоящая публичная Оферта (далее по тексту — Договор) является официальным предложением Общества с ограниченной ответственностью “МаркетГуру” (ОГРН 1216100023897, ИНН 6154160950), действующего без образования юридического лица (далее по тексту – Лицензиар) в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса РФ'
        },
        loadComponent: () =>
          import('./public/privacy-promotion/privacy-promotion.component').then(
            (c) => c.PrivacyPromotionComponent
          )
      },
      {
        path: 'confidentiality',
        data: {
          title: 'MarketGuru — Политика конфиденциальности',
          description: 'MarketGuru — Политика конфиденциальности'
        },
        loadComponent: () =>
          import('./public/confidentiality/confidentiality.component').then(
            (c) => c.ConfidentialityComponent
          )
      },
      {
        path: 'calculator',
        redirectTo: 'tools/calculator'
      },
      {
        path: 'contacts',
        data: {
          title: 'MarketGuru - сервис аналитики и увеличения продаж на Wildberries',
          description:
            'Сервис аналитики и увеличения продаж на Wildberries - MarketGuru. Найди прибыльные ниши, тренды, планируй поставки, выводите товары в ТОП на Вайлдберриз.'
        },
        loadComponent: () =>
          import('./public/contacts/contacts.component').then((c) => c.ContactsComponent)
      },
      {
        path: 'docs',
        data: {
          title: 'База знаний MarketGuru'
        },
        loadChildren: () => EducationModule
      },
      {
        path: 'tools',
        loadChildren: () => import('./public/tools').then((c) => c.TOOLS_ROUTES)
      }
    ]
  },
  {
    path: 'extension',
    loadComponent: () =>
      import('./public/extension/extension.component').then((c) => c.ExtensionComponent)
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];
