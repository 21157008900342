<div [class.header-wrapper--initial]="isDocsPage()" class="header-wrapper">
  <div [class.container--wide]="isDocsPage()" class="container">
    <div class="header">
      <mg-logo height="4.2rem" />

      <app-header-nav [isOpen]="isNavOpened()" (closeMenu)="navToggle()" />

      <div class="m-auto"></div>

      <a
        [href]="mainHost + '/auth/signin' + utmService.utmQuery() + '&utm_button_target=signin-button-header'"
        appearance="secondary"
        data-testid="signin-button-header"
        mg-button
      >
        Войти
      </a>

      @if (isShowRegButton()) {
        <a
          [href]="mainHost + '/auth/signup' + utmService.utmQuery() + '&utm_button_target=signup-button-header'"
          accent
          class="desktop ml-12"
          data-testid="signup-button-header"
          iconRight="mg-icon-double-right"
          mg-button
        >
          Попробовать бесплатно
        </a>
      }

      <button
        (click)="navToggle()"
        appearance="secondary"
        class="mobile ml-16"
        iconLeft="mg-icon-dots"
        mg-button-icon
      ></button>
    </div>
  </div>
</div>
